import { getUser, setUser } from "../utils/auth"
import User from "../models/User"
import getFirebase, { getCollectionByName } from "../utils/firebase"
import { useSelector, useDispatch } from 'react-redux'
import {setUserLogin} from '../features/User/userSlice'

const firebase = getFirebase()
const userInfoCollection = getCollectionByName("userInfo")
const topicsCollection = getCollectionByName("topics")

export function loadingUserInfo() {
  var docRef = userInfoCollection.doc(getUser().uid)

  return docRef.get().then(doc => {
    if (doc.exists) {
      const userInfo = doc.data()
      // Get userInfo from localStorage
      const olderUserInfo = getUser()

      // Reassign allTopics to user
      userInfo.allTopics = olderUserInfo.allTopics
      setUser(userInfo)

      return userInfo
    } else {
      return getUser()
    }
  })
}

// Save data after login
export function handlerAfterLoggedIn() {
  const user = fromFirebase()

  // Save userData to cache
  setUser(user)

  topicsCollection.get().then(querySnapshot => {
    const allTopics = []

    querySnapshot.forEach(doc => {
      allTopics.push({
        ...doc.data(),
        id: doc.id,
      })
    })

    user.allTopics = allTopics
    // Update topics to user
    setUser(user)
  })
}

export function addOrUpdateUserInfo(userInfo) {
  var user = fromFirebase()

  user.displayName = userInfo.displayName || ""
  user.phoneNumber = userInfo.phoneNumber || ""
  user.sex = userInfo.sex || ""
  user.address = userInfo.address || ""
  user.birthday = userInfo.birthday || null

  return userInfoCollection.doc(user.uid).set(Object.assign({}, user))
}

function fromFirebase() {
  const user = new User()

  user.uid = firebase.auth().currentUser.uid
  user.displayName = firebase.auth().currentUser.displayName || ""
  user.photoURL = firebase.auth().currentUser.photoURL || ""
  user.email = firebase.auth().currentUser.email || ""

  return user
}
