import { configureStore } from "@reduxjs/toolkit";

import UserSlice from '../features/User/userSlice';

const rootReducer = {
    user: UserSlice
};
const store = configureStore({
    reducer: rootReducer
});
export default store;