
import { createSlice } from "@reduxjs/toolkit";
import {isLoggedIn} from '../../utils/auth'

const UserSlice = createSlice({
    name: 'user',
    initialState: {
        isLogin: isLoggedIn(),
    },
    reducers: {
        setUserLogin: (state) => {
            state.isLogin = true
        },
        setUserLogout: (state) => {
            state.isLogin = false
        }
    }
})

const {reducer, actions} = UserSlice
export const {setUserLogin, setUserLogout} = actions
export default reducer