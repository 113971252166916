import firebase from "gatsby-plugin-firebase"

import { FRAMEWORKS } from "../constants/Frameworks"

const firebaseConfig = {
  apiKey: "AIzaSyB7Cg4iKEfrc6vZSwd-mxf0QqPr1tdUMrA",
  authDomain: "world-works-dojo.firebaseapp.com",
  databaseURL: "https://world-works-dojo.firebaseio.com",
  projectId: "world-works-dojo",
  storageBucket: "world-works-dojo.appspot.com",
  messagingSenderId: "111406784612",
  appId: "1:111406784612:web:6a30074d8b11359df97f71",
  measurementId: "G-9HKHQ5E1SK",
}

if (typeof window !== "undefined") {
  if (process.env.REACT_APP_FRAMEWORK === FRAMEWORKS.REACTJS) {
    firebase.initializeApp(firebaseConfig)
    firebase.analytics()
  }

  if (window.location.hostname === "localhost") {
    firebase.firestore().settings({
      host: "localhost:8080",
      ssl: false,
    })
  }
}

export default function getFirebase() {
  if (typeof window !== "undefined") {
    return firebase
  }

  return null
}

export function getCollectionByName(name) {
  if (typeof window !== "undefined") {
    return firebase.firestore().collection(name)
  }

  return {}
}
