import React from "react";
import {
  FaFacebookF
} from "react-icons/fa";
import Logo from "../../data/menu-header/media/world-logo.png";
import ALink from "../common/ALink";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: "linear-gradient(to right, #5da5ee, #2278CF)",
  },
  distributed:{
    display:"flex",
    [theme.breakpoints.down("xs")]:{
      flexDirection: "column"
    }
  },
  logo:{
    width:"30%",
    textAlign:"center",
    [theme.breakpoints.down("xs")]:{
      width:"100%",
    }
  },
  logoimg:{
    width:"70%",
    paddingTop:"38px",
    [theme.breakpoints.down("xs")]:{
      width:"50%",
    }
  },
  content:{
    flex:1
  },
  title:{
    padding:"25px 0 0 0 ",
    width:"100%",
    textAlign:"center",
    
  },
  titleContent:{
    fontSize:"30px",
    color:"white",
    margin:"0",
    [theme.breakpoints.down("xs")]:{
      fontSize:"2em",
    }
  },
  menu:{
    display:"flex",
    paddingLeft:"5px",
    [theme.breakpoints.down("xs")]:{
      paddingLeft:"0"
    }
  },
  navbar:{
    listStyle:"none",

  },
  companyinforowBottom: {
    width: "70%",
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  item: {
    textDecoration: "none",
  },
  itemChild: {
    paddingBottom: "10px",
    color: "white",
    width: "85%",
    lineHeight: "25px",
    fontSize: "20px",
    cursor: "pointer",
    "&:hover": {
      color: "#E9FC06",
    },
    [theme.breakpoints.down("xs")]: {
      lineHeight: "20px",
      fontSize: "15px",
    },
  },
  icon: {
    width: "100%",
    height: "100%",
  },
  icons: {
    marginRight: "-10px",
    "& a": {
      display: "inline-block",
      padding: "4px",
      width: "35px",
      height: "35px",
      cursor: "pointer",
      borderRdius: "2px",
      fontSize: "1.3rem",
      textAlign: "center",
      marginRight: "10px",
    },
  },
  iconfb: {
    backgroundColor: "#3366cc",
    color: "#ffff",
    "&:hover": {
      backgroundColor: "#ffff",
      color: "#3366cc",
    },
  },
  copyright: {
    textAlign: "center",
    padding: "20px",
    color: "white",
    margin: 0,
  },
  link: {
    textDecoration: "none",
    cursor: "pointer",
    color: "white",
    "&:hover": {
      color: "#E9FC06",
    },
  },
}))

export default function Footer() {
  const classes = useStyles()
  return (
    <footer className={classes.root}>
      <Container>
        <div className={classes.title}>
          <h3 className={classes.titleContent}>WORLD JAPANESE DOJO</h3>
        </div>
        <div className={classes.distributed}>
          <div className={classes.logo}>
            <ALink to="/home" className={classes.logolink}>
              <img
                src={Logo}
                alt="world-logo"
                id="logo"
                className={classes.logoimg}
              />
            </ALink>
          </div>
          <div className={classes.content}>
            <div className={classes.menu}>
              <div className={classes.companyinforowTop}>
                <ul className={classes.navbar}>
                  <ALink to="/home" className={classes.item}>
                    <li className={classes.itemChild}>Home</li>
                  </ALink>
                  <ALink to="/" className={classes.item}>
                    <li className={classes.itemChild}>Landing Page</li>
                  </ALink>
                  <ALink to="/topics" className={classes.item}>
                    <li className={classes.itemChild}>Khoá Học</li>
                  </ALink>
                  <ALink to="/blog" className={classes.item}>
                    <li className={classes.itemChild}>Bài viết</li>
                  </ALink>
                  <ALink to="/support" className={classes.item}>
                    <li className={classes.itemChild}>Hỗ trợ</li>
                  </ALink>
                  <ALink to="/terms-and-security/1" className={classes.item}>
                    <li className={classes.itemChild}>Điều khoản sử dụng</li>
                  </ALink>
                </ul>
              </div>
              <div className={classes.companyinforowBottom}>
                <ul className={classes.navbar}>
                  <a
                    className={classes.item}
                    href="https://world-works.co.jp/corporate/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <li className={classes.itemChild}> 株式会社ワールド</li>
                  </a>
                  <a
                    className={classes.item}
                    href="https://www.google.com/maps/place/%E3%80%92500-8856+%E5%B2%90%E9%98%9C%E7%9C%8C%E5%B2%90%E9%98%9C%E5%B8%82%E6%A9%8B%E6%9C%AC%E7%94%BA%EF%BC%92%E4%B8%81%E7%9B%AE%EF%BC%98+%E6%BF%83%E9%A3%9B%E3%83%8B%E3%83%83%E3%82%BB%E3%82%A4%E3%83%93%E3%83%AB/@35.4109201,136.7505723,17z/data=!3m1!4b1!4m5!3m4!1s0x6003a949f84e9fad:0x3399170b5214b091!8m2!3d35.4109201!4d136.752761"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <li className={classes.itemChild}> 〒500-8856 </li>
                    <li className={classes.itemChild}>
                      Gifu-ken Gifu-shi Hashimoto-cho 2-8 Nouhi Nissei Building
                      6F
                    </li>
                  </a>
                  <a
                    href="tel://058-255-3066"
                    className={classes.item}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <li className={classes.itemChild}>TEL 058-255-3066</li>
                  </a>
                  <a
                    href="tel://058-255-3633"
                    className={classes.item}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <li className={classes.itemChild}>FAX 058-255-3633</li>
                  </a>
                  <div className={classes.icons}>
                    <a
                      href="https://www.facebook.com/World.Japanese.Dojo/"
                      className={classes.iconfb}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaFacebookF />
                    </a>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <p className={classes.copyright}>
          Copyright © 2020 by{" "}
          <a
            className={classes.link}
            href="https://world-works.co.jp/corporate/"
            target="_blank"
            rel="noreferrer"
          >
            株式会社ワールド
          </a>
          . All rights reserved
        </p>
      </Container>
    </footer>
  )
}
