export default class User {
  uid
  displayName
  phoneNumber
  photoURL
  sex
  birthday
  email
  address
}
