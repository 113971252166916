import React, { useState } from "react"
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import getFirebase from "../../../utils/firebase"
import { handlerAfterLoggedIn } from "../../../api/user.service"
import { getUser } from "../../../utils/auth"
import { useSelector, useDispatch } from "react-redux"
import { setUserLogin } from "../../../features/User/userSlice"
import { navigate } from "@reach/router"

const firebase = getFirebase()

function Login({ redirectTo }) {
  let title = "Đăng nhập vào website World Japanese Dojo"
  let messenger = "Đăng nhập bằng tài khoản:"
  const dispatch = useDispatch()
  const isLogin = useSelector(state => state.user.isLogin)
  // Configure FirebaseUI
  const uiConfig = () => {
    return {
      signInFlow: "popup",
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      ],
      callbacks: {
        signInSuccessWithAuthResult: () => {
          handlerAfterLoggedIn()
          dispatch(setUserLogin())
          if (redirectTo) {
            setTimeout(() => {
              navigate(redirectTo)
              // Only ReactJs
              if (process.env.REACT_APP_FRAMEWORK === "ReactJs") {
                window && window.location.reload()
              }
            }, 1000)
          }
        },
      },
    }
  }
  return (
    <div style={{ textAlign: "center" }}>
      {!isLogin ? (
        <div>
          <h2>{title}</h2>
          <p>{messenger}</p>
        </div>
      ) : (
        <h2>Chào mừng bạn {getUser().displayName}</h2>
      )}

      {firebase && (
        <StyledFirebaseAuth
          uiConfig={uiConfig()}
          firebaseAuth={firebase.auth()}
        />
      )}
    </div>
  )
}
export default Login
