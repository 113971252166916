/* eslint-disable jsx-a11y/alt-text */
import React from "react"
import Img from "gatsby-image"

export  function getImg(src) {
  const results = src.split("/")
  const category = results[results.length - 2]
  switch (category) {
    case "media":
      return require("../../data/" + src)
    default:
      return src
  }
}

export default function Image(props) {
  if (!props.src.childImageSharp) {
    return (
      <img
        alt={props.alt}
        src={getImg(props.src)}
        className={props.className}
        id={props.id}
      ></img>
    )
  } else {
    return (
      <Img
        className={props.className}
        id={props.id}
        fluid={props.src.childImageSharp.fluid}
        atl={props.alt}
      />
    )
  }
}
