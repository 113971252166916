import React from "react"
import HeaderMenuBar from "./HeaderMenuBar"
import Footer from "./Footer"
import { useStaticQuery, graphql } from "gatsby"
import { Provider } from "react-redux"

import store from "../../app/store"

function withRoot(Component) {
  function WithRoot(props) {
    const data = useStaticQuery(graphql`
      query {
        menuHeaderJson {
          leftMenu {
            name
            path
            img {
              alt
              src {
                childImageSharp {
                  fluid {
                    srcSet
                  }
                }
              }
            }
          }
        }
      }
    `)
    return (
      <Provider store={store}>
        <div></div>
        <HeaderMenuBar leftMenu={data.menuHeaderJson.leftMenu} />
        <Component
          {...props}
        />
        <Footer />
      </Provider>
    )
  }

  return WithRoot
}
export default withRoot
