import React, { useState, useEffect } from "react"
import clsx from "clsx"
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import CssBaseline from "@material-ui/core/CssBaseline"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Avatar from "@material-ui/core/Avatar"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import Button from "@material-ui/core/Button"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Paper from "@material-ui/core/Paper"
import MenuList from "@material-ui/core/MenuList"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import PersonPinIcon from "@material-ui/icons/PersonPin"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import { Close } from "@material-ui/icons"
import Login from "../learning/pageContents/Login"
// import LanguageOutlinedIcon from "@material-ui/icons/LanguageOutlined"
// import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined"
// import useMediaQuery from "@material-ui/core/useMediaQuery"
// import MenuItem from "@material-ui/core/MenuItem"
import ALink from "../common/ALink"
// src logo

import Logo from "../../data/menu-header/media/world-logo.png"
import Image from "../common/Image"
import getFirebase from "../../utils/firebase"
import { getUser, isLoggedIn, logout } from "../../utils/auth"
import { useSelector, useDispatch } from "react-redux"
import { setUserLogout } from "../../features/User/userSlice"
// const optionsLanguage = ["Tiếng Việt", "日本語"]

const drawerWidth = 200
const firebase = getFirebase()
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundImage: "linear-gradient(to right, #5da5ee, #2278CF)",
    // height:"10%"
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(0),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  icon: {
    margin: "auto",
    marginRight: 8,
    width: 20,
    height: 20,
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    padding: "0px",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  DialogContent: {
    padding: theme.spacing(10, 6),
  },
  loginTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  SingInButton: {
    textTransform: "none",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.grey[500],
    zIndex: 1,
  },
  logo: {
    marginTop: 5,
    marginLeft: 10,
    width: 70,
  },
  languageButtonText: {
    fontFamily: "'Roboto Condensed', sans-serif",
  },
  link: {
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:active": {
      color: "#fff",
    },
    "& span": {
      fontWeight: 100,
    },
  },
  login: {
    backgroundColor: "#fff",
    "& span": {
      "& a": {
        color: "#2278CF",
      },
    },
  },
}))
const StyledMenu = withStyles({
  paper: {
    boxShadow:
      "0 2px 10px 0 rgba(0, 0, 0, 0.18), 0 2px 10px 0 rgba(0, 0, 0, 0.15)",
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles(theme => ({
  root: {
    "& .MuiListItemIcon-root": {
      color: theme.palette.primary.main,
    },
    "& .MuiListItemText-primary": {
      color: theme.palette.grey[800],
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem)

function HeaderMenuBar(props) {
  const menuList = props.leftMenu
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const [openLogin, setOpenLogin] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const dispatch = useDispatch()
  const isLogin = useSelector(state => state.user.isLogin)
  const [firebaseCreated, setFirebaseCreated] = useState(false)

  useEffect(() => {
    firebase &&
      firebase.auth().onAuthStateChanged(function (user) {
        setFirebaseCreated(true)
      })
  }, [firebase])

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleClickOpen = () => {
    setOpenLogin(true)
  }

  const handleClose = () => {
    setOpenLogin(false)
  }

  const handleAvatarOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleAvatarClose = () => {
    setAnchorEl(null)
  }
  const handleLogout = () => {
    firebase &&
      logout(firebase).then(() => {
        setAnchorEl(null)
        dispatch(setUserLogout())
      })
  }
  //Language change
  const [openLanguage, setOpenLanguage] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = React.useState(1)

  const handleMenuLanguage = (event, index) => {
    setSelectedIndex(index)
    setOpenLanguage(false)
  }

  const handleToggle = () => {
    setOpenLanguage(prevOpen => !prevOpen)
  }

  const handleCloseLanguage = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpenLanguage(false)
  }
  function isAuthenticated() {
    if (isLogin) {
      return true
    }
    return firebaseCreated && firebase.auth().currentUser
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>

          <Typography className={classes.title} noWrap>
            <ALink to="/home">
              <img src={Logo} alt="" className={classes.logo} />
            </ALink>
          </Typography>

          <div style={firebaseCreated ? {} : { display: "none" }}>
            <div style={isAuthenticated() ? {} : { display: "none" }}>
              <IconButton onClick={handleAvatarOpen}>
                <Avatar src={getUser().photoURL} />
              </IconButton>
              <StyledMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleAvatarClose}
              >
                <StyledMenuItem onClick={handleAvatarClose}>
                  <ALink className={classes.link} to="/app/user">
                    <ListItemIcon>
                      <PersonPinIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Tài khoản" />
                  </ALink>
                </StyledMenuItem>

                <StyledMenuItem onClick={handleLogout}>
                  <ALink className={classes.link} to="/">
                    <ListItemIcon>
                      <ExitToAppIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Đăng xuất" />
                  </ALink>
                </StyledMenuItem>
              </StyledMenu>
            </div>

            <div style={isAuthenticated() ? { display: "none" } : {}}>
              <Button
                edge="start"
                className={classes.SingInButton}
                color="inherit"
                onClick={handleClickOpen}
              >
                Đăng nhập
              </Button>
            </div>
          </div>
          {/* 
          <Button
            className={(classes.menuButton, classes.languageButtonText)}
            edge="start"
            color="inherit"
            ref={anchorRef}
            aria-label="split button"
            aria-controls={openLanguage ? "split-button-menu" : undefined}
            aria-expanded={openLanguage ? "true" : undefined}
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <LanguageOutlinedIcon className={classes.marginIcon} />
            {isNotMobile ? optionsLanguage[selectedIndex] : ""}
            <KeyboardArrowDownOutlinedIcon />
          </Button>
          <Popper
            open={openLanguage}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Paper>
                <ClickAwayListener onClickAway={handleCloseLanguage}>
                  <MenuList id="split-button-menu">
                    {optionsLanguage.map((option, index) => (
                      <MenuItem
                        className={classes.languageButtonText}
                        key={option}
                        selected={index === selectedIndex}
                        onClick={event => handleMenuLanguage(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            )}
          </Popper> */}
        </Toolbar>
      </AppBar>

      <Dialog
        open={openLogin}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <Close />
        </IconButton>

        <DialogContent
          classes={{
            root: classes.DialogContent,
          }}
        >
          <Login />
        </DialogContent>
      </Dialog>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />

        <List>
          {menuList.map((item, index) => {
            return (
              <ListItem
                key={index}
                button
                component={ALink}
                to={item.path}
                onClick={handleDrawerClose}
              >
                <Image className={classes.icon} {...item.img} />
                <ListItemText primary={item.name} />
              </ListItem>
            )
          })}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
      </main>
    </div>
  )
}

export default HeaderMenuBar
